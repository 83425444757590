import { createApp } from 'vue';
import { createPinia } from 'pinia';
import antd from 'ant-design-vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { router } from '@/router';

import 'ant-design-vue/dist/reset.css';

import '@/assets/styles/global.scss';
import '@/assets/styles/utility.scss';
import '@/assets/styles/custom-antd.scss';
import '@/assets/styles/custom-ckeditor.scss';

import DatePicker from 'ant-design-vue/es/date-picker/moment';
import TimePicker from 'ant-design-vue/es/time-picker/moment';
import Calendar from 'ant-design-vue/es/calendar/moment';
import 'moment/dist/locale/ko';

import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Highstock from 'highcharts/highstock';
import boost from 'highcharts/modules/boost';
import annotations from 'highcharts/modules/annotations';
import * as Sentry from '@sentry/vue';
import sentryConfig from '@/utils/sentry';
import CKEditor from '@ckeditor/ckeditor5-vue';

import HTag from '@/components/hds/tags/HTag.vue';
import { AutoReloader } from './utils/classes/AutoReloader';

boost(Highcharts);
annotations(Highcharts);
More(Highcharts);
Highcharts.setOptions({
  credits: { enabled: false },
});

Highstock.setOptions({
  credits: { enabled: false },
});

Sentry.init(sentryConfig);

export const boot = (app: ReturnType<typeof createApp>, isMount = true) => {
  app.component('ckeditor', CKEditor.component);
  app.use(createPinia()).use(router).use(VueQueryPlugin).use(antd).use(DatePicker).use(TimePicker).use(Calendar);
  
  app.component('h-tag', HTag);
  
  app.provide('autoReloader', new AutoReloader());

  if (isMount) {
    app.mount('#app');
  }

  return app;
};

import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { refresh$, useToken } from '@/hooks/useToken';
import { Routes } from '@/constants/routes';
import decode from 'jwt-decode';
import { JWTPayload } from '@/schema/jwt.schema';
import { useQueryClient } from '@tanstack/vue-query';
import Logger from '@/utils/classes/Logger';

const logger = new Logger('useAuthPage', {
  isDebug: false,
});

export const useAuthPage = () => {
  const router = useRouter();
  const route = useRoute();

  const authorized = ref(false);

  const { getRefreshToken, clearRefreshToken } = useToken();

  const queryClient = useQueryClient();

  onMounted(() => {
    const refresh = getRefreshToken();
    if (refresh) {
      const data = JWTPayload.parse(decode(refresh));
      logger.scope('onMounted').debug(data);
      authorized.value = true;
    }

    if (route.path === Routes.LOGIN) {
      if (refresh) {
        router.push({
          path: Routes.AUTHORIZED,
          replace: true,
        });
      }
    } else if (route.path === Routes.AUTHORIZED) {
      if (!refresh) {
        router.push({
          path: Routes.LOGIN,
          replace: true,
        });
      }
    } else {
      if (!refresh) {
        router.push({
          path: Routes.LOGIN,
          query: {
            redirect: route.path,
          },
          replace: true,
        });
      }
    }
  });

  const isAuthorized = () => {
    const refresh = getRefreshToken();
    return !!refresh;
  }

  const onAfterLogined = () => {
    const redirect = route.query.redirect ? String(route.query.redirect) : '/';
    router.push({
      path: redirect,
      replace: true,
    });
  };

  const logout = () => {
    clearRefreshToken();
    queryClient.invalidateQueries();
    if (route.path === Routes.AUTHORIZED) {
      router.push({
        path: Routes.LOGIN,
        replace: true,
      });
    } else {
      router.push({
        path: Routes.LOGIN,
        query: {
          redirect: route.path,
        },
        replace: true,
      });
    }
  };

  let timeoutRefreshExp: NodeJS.Timeout | null = null;

  function setLongTimeout(callback: Function, ms: number) {
    if (ms > 2147483647) {
      timeoutRefreshExp = setTimeout(() => setLongTimeout(callback, ms - 2147483647), 2147483647);
    } else {
      timeoutRefreshExp = setTimeout(() => callback(), ms);
    }
  }

  refresh$.subscribe((refresh) => {
    if (refresh === null) {
      return;
    }
    
    if (timeoutRefreshExp !== null) {
      clearTimeout(timeoutRefreshExp);
      timeoutRefreshExp = null;
    }

    const { exp } = decode<{ exp: number }>(refresh);

    setLongTimeout(() => {
      logout();
    }, exp * 1000 - Date.now());
  });

  return {
    authorized,
    isAuthorized,
    onAfterLogined,
    logout,
  };
};

import Cookies from 'js-cookie';

import decode from 'jwt-decode';
import { JWTPayload } from '@/schema/jwt.schema';
import { useUserStore } from '@/store/useUserStore';
import { useRequestStore } from '@/store/useRequestStore';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { Subject } from 'rxjs';

const REFRESH_TOKEN_KEY = 'haezoom-v__refresh';
export const refresh$ = new Subject<string | null>();

export const useToken = () => {
  const userStore = useUserStore();

  const decodeToken = (token: string) => {
    return JWTPayload.parse(decode(token));
  }

  const requestStore = useRequestStore();
  const { accessToken} = storeToRefs(requestStore);

  watch(accessToken, (cur) => {
    if (cur) {
      const info = decodeToken(cur);
      userStore.setUserInfo({
        name: info.name,
        email: info.email,
        teamList: info.team,
        isControllable: info.team.includes('운영팀'),
      });
    }
  });

  const setRefreshToken = (refresh: string) => {
    Cookies.set(REFRESH_TOKEN_KEY, refresh);
    refresh$.next(refresh);
  }

  const getRefreshToken = () => {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  const clearRefreshToken = () => {
    refresh$.next(null);
    userStore.setUserInfo(null);
    Cookies.remove(REFRESH_TOKEN_KEY);
  }

  const refresh = getRefreshToken();
  
  if (refresh) {
    refresh$.next(refresh);
  }

  return {
    setRefreshToken,
    getRefreshToken,
    clearRefreshToken,
  }
}